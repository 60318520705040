@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 228.89 50.94% 31.18%;
    /* --primary: 221.2 83.2% 53.3%; */
    --primary-foreground: 210 40% 98%;
    --secondary: 190 20% 94%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 4 64% 51%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;

    --positive: 131 39% 44%;
    --positive-foreground: 355.7 100% 97.3%;
  
    --warning: 34 76% 50%;
    /* --warning-foreground: 222.2 84% 4.9%; */
    --warning-foreground: 355.7 100% 97.3%;

    --neutral: 0 0% 91%;
    --neutral-foreground: 222.2 84% 4.9%;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive:359 100% 66%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
 
    --positive: 119 65% 36%;
    --positive-foreground: 210 40% 98%;

    --warning: 23 71% 48%;
    --warning-foreground: 210 40% 98%;
    
    --neutral: 0 0% 30%;
    --neutral-foreground: 210 40% 98%;
 }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }

  .array-svg-container svg {
    width: 100%;
    height: auto;
    /* optionally ensure it respects its aspect ratio */
    max-width: 100%;
    display: block;
  }
}